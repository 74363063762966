import {
  LOCATION_CHANGE_BACKWARD,
  LOCATION_CHANGE_FORWARD,
  TO_STEP_REVISION,
  SUBMISSION_SUCCESS,
} from './../actions/navigate';

const initialState = {
  inReviewMode: false,
  stepProps: {},
};

const scrollToTop = () => window.scrollTo(0, 0);

export default function navigateReducer(state = initialState, action) {
  switch(action.type) {
    case TO_STEP_REVISION: {
      scrollToTop();
      return {
        ...state,
        inReviewMode: true
      };
    }
    case LOCATION_CHANGE_FORWARD: {
      scrollToTop();
      return {
        ...state,
        stepProps: action.payload.data.stepProps
      }
    }
    case LOCATION_CHANGE_BACKWARD:
    case SUBMISSION_SUCCESS:
      scrollToTop();
      return state;
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
