import React from 'react';
import StepWizard from 'react-step-wizard';
import {
  Header,
  Footer,
  RegisterEntity,
  CreateEntityManager,
  CauseDetails,
  PayPal,
  Review,
  Card,
  CauseSelection,
  Nav
} from './../';

let style = {
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  content: {
    position: 'relative',
    flex: 1,
    maxWidth: 900,
    width: '95%',
    marginTop: 40,
    marginBottom: 40,
  },
}

const RegisterSponsorPlus = (props) => {

  const steps = [
    'Register Sponsor',
    'Add Manager',
    'Sponsor Details',
    'Cause Selection',
    'Review'
  ];

  return (
    <div style={style.root}>
      <Header />
      <div style={style.content}>
        <StepWizard isHashEnabled={true} nav={<Nav steps={steps}/>} isLazyMount={false}>
          <RegisterEntity
            hashKey={'sponsorplus-join/register'}
            type="Sponsor"
            title="Register Sponsor"
            caption="Setup your organization as a greenback Registered Sponsor by providing your legal entity information."
          />
          <CreateEntityManager
            hashKey={'sponsorplus-join/create-manager'}
            title="Add Sponsor Manager"
            caption="Create your Registered Sponsor Manager Account by completing the form below."
          />
          <CauseDetails
            hashKey={'sponsorplus-join/details'}
            registrationType="sponsor"
            type="Sponsor"
            />
          <CauseSelection
            hashKey={'sponsorplus-join/cause-selection'}
            registrationType="sponsorplus"
            type="Sponsor"
            caption="Select the causes you wish to support. Your customers will be able to donate to these causes to receive a new membership to greenback, or extend their existing membership while staying connected to your organization."
            />
          <Review
            hashKey={'sponsorplus-join/review'}   registrationType="sponsorplus"
            type="Sponsor"

          />
        </StepWizard>
      </div>
      <Footer />
    </div>
  )
}

export default RegisterSponsorPlus;
