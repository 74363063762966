import React, { Component } from 'react';
import { reducer as toastr } from 'react-redux-toastr'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import {
  HashRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
  withRouter
} from "react-router-dom";

import {
  RegisterCause,
  RegisterSponsor,
  RegisterSponsorPlus,
  Unauthorized,
  PrivateRoute,
  RegisterSubmission,
} from './components';

class App extends Component {

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/unauthorized" component={Unauthorized} />
          <PrivateRoute path="/cause/:token" component={RegisterCause} />
          <PrivateRoute path="/cause-join/:subroute" component={RegisterCause} />
          <PrivateRoute path="/sponsor/:token" component={RegisterSponsor} />
          <PrivateRoute path="/sponsor-join/:subroute" component={RegisterSponsor} />
          <PrivateRoute path="/sponsorplus/:token" component={RegisterSponsorPlus} />
          <PrivateRoute path="/sponsorplus-join/:subroute" component={RegisterSponsorPlus} />
          <Route path="/join-complete" component={RegisterSubmission} />
          <Route component={Unauthorized} />
        </Switch>
      </Router>
    );
  }

}

export default App;
