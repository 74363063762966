import { delay } from 'redux-saga';
import { put, takeEvery, takeLatest, all,  select, call} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import _ from 'lodash';
import {
  fetchAccountManagerBegin,
  fetchAccountManagerSuccess,
  fetchAccountManagerFailure,
  fetchAccountManagerByToken,
} from './../actions/accountManager';

import {
  FETCH_ACCOUNTMANAGER_BEGIN,
  FETCH_ACCOUNTMANAGER_SUCCESS,
  FETCH_ACCOUNTMANAGER_FAILURE
} from './../actions/accountManager';

import storage from './../aor-loopback/storage';

export const getState = (state) => {
  return state.accountManagerReducer;
}

function* onFailure(action) {
  return new Promise((resolve) => {
    window.location.replace('/#/unauthorized');
  });
}

function* setAccountManagerState(action) {
  const { type } = action;
  const { error, loading, record } = yield select(getState);
  try {
    const lbtoken = record.token || storage.load('lbtoken');
    if (!lbtoken) throw new Error("cannot get user.");
    const accountMgr = yield fetchAccountManagerByToken({token: lbtoken, action});
    if (!_.get(accountMgr, 'response.json')) throw new Error(accountMgr);
    yield put(fetchAccountManagerSuccess({
      account_manager: accountMgr.response.json,
      token: lbtoken
    }));
  }
  catch(err) {
    yield put(fetchAccountManagerFailure(err));
  }
}

function* setLocalSotrageToken(action) {
  const { type } = action;
  const { error, loading, record } = yield select(getState);
  if (!error && record.token) {
    storage.save('lbtoken', record.token, 604800);
    storage.save('account_manager_id', _.get(record, 'account_manager.id'), 604800);
  }
}


export default function* watchAccountManager() {
  yield all([
    takeLatest(FETCH_ACCOUNTMANAGER_BEGIN, setAccountManagerState),
    takeLatest(FETCH_ACCOUNTMANAGER_SUCCESS, setLocalSotrageToken),
    takeLatest(FETCH_ACCOUNTMANAGER_FAILURE, onFailure)
  ]);
}
