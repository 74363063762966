import React from 'react';
import { connect } from 'react-redux';
import {
  Card,
} from './../';
import {
  CheckCircle
} from '@material-ui/icons';
import _ from 'lodash';

import config from './../../config/config.provider';

let style = {
  navRoot: {
    marginBottom: 20,
    '@media (max-width: 600px)': {
      display: 'none',
    }
  },
  navContent: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  stepWrapper: {
    display: 'flex',
    // width: '100%',
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 10,
    letterSpacing: 0.5,
    color: '#A4ACAF',
    fontFamily: 'ProximaNova-SemiBold',
    textTransform: 'uppercase',
    cursor: 'pointer',
    flexShrink: 0,
    ':after': {
      position: 'absolute',
      content: '',
      width: 120,
      height: 1,
      backgroundColor: 'red',
    }
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    borderWidth: 1,
    borderColor: '#34B0A7',
    borderStyle: 'solid',
    marginBottom: 10,
  },
  dotActive: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    borderWidth: 1,
    borderColor: '#34B0A7',
    borderStyle: 'solid',
    marginBottom: 10,
    backgroundColor: '#34B0A7',
  },
  dotCompleted: {
    width: 16,
    height: 16,
    color: '#34B0A7',
    marginBottom: 10,
  },
}


const Nav = (props) => {
  const dots = [];
  let activeFound = false, dotEl;
  for (let i = 1; i <= props.totalSteps; i++) {
    const isActive = props.currentStep === i;
    //active step
    if (isActive) {
      activeFound = true;
      dotEl = <div style={style.dotActive} />
    }
    //completed step
    else if (!activeFound && !props.inReviewMode || (props.inReviewMode && i !== props.totalSteps)) {
      dotEl = <CheckCircle style={style.dotCompleted} />
    }
    //incomplete step
    else {
      dotEl = <div style={style.dot} />
    }
    dots.push((
      <div style={style.stepWrapper} key={`step-${i}`}>
        <div
          style={style.step}
          onClick={() => config.env == "dev" && props.goToStep(i)}
        >
          {dotEl}
          {props.steps[`${i-1}`]}
        </div>
      </div>
    ));
  }

  return (
    <Card content={style.navContent} root={style.navRoot}>
      {dots}
    </Card>
  );
};

export default connect((state) => {
  return {
    inReviewMode: _.get(state, 'navigateReducer.inReviewMode', false)
  }; 
})(Nav);
