import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Header,
  Footer,
  Card
} from './../';

let style = {
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    height: '100vh',
  },
  card: {
    paddingTop: 60,
    paddingBottom: 60,
    '@media (max-width: 600px)': {
      paddingTop: 20,
      paddingBottom: 20,
    }
  },
  content: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    maxWidth: 900,
    width: '95%',
    marginTop: 40,
    marginBottom: 40,
  },
  title: {
    textAlign: 'center',
    fontSize: 36,
    marginTop: 100,
    '@media (max-width: 600px)': {
      fontSize: 22,
      marginTop: 40,
    }
  },
  caption: {
    marginTop: 40,
    lineHeight: 1.7,
    maxWidth: 700,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (max-width: 600px)': {
      fontSize: 14
    }
  },
  loading: {
    position: 'relative',
    display: 'block',
    width: 200,
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (max-width: 600px)': {
      width: 100,
    }
  },
  link: {
    color: '#0e9ba6',
    marginLeft: 10,
    marginRight: 10,
    textDecoration: 'none',
  },
}

const Error = (props) => {
	return (
		<Card root={style.card}>
			<div style={style.title}>Uh oh! It seems we have run into an issue while registering your organization with Greenback.</div>
			<div style={style.caption}>Please contact us at <a href="mailto:help@gogreenback.com" style={style.link}>help@gogreenback.com</a> so that we can help resolve this issue and complete your registration!</div>
		</Card>
	);
}

const Success = (props) => {
	return (
		<Card root={style.card}>
			<img style={style.loading} src="//cdn.startupmedia.com/greenback/icons/gb-icon-spinning.svg" />
			<div style={style.title}>Confirming your account...</div>
			<div style={style.caption}>Please allow us up to two minutes to confirm your account.  Afterwards you'll be automatically directed to your greenback Management Portal.</div>
		</Card>
	);
}

const RegisterSubmission = (props) => {

  const { loading, record, error } = props.registration_state;
  const hasValidSubmission = _.get(record, 'registration.entity_creation_result.cause.id');

  return (
    <div style={style.root}>
      <Header />
      <div style={style.content}>
        {(error || (!loading && !hasValidSubmission)) ? <Error /> : <Success />}
      </div>
      <Footer />
    </div>
  )
}

export default connect((state) => {
  return {
    registration_state: state.registrationReducer
  }
})(RegisterSubmission);
