import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { getCauses } from './../../services';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Tooltip } from './../';
import _ from 'lodash';

const style = {
  wrapper: {
    position: 'relative',
    width: '100%',
    display: 'flex',
  },
  input: {
    color: '#464D5E',
    fontFamily: "'ProximaNova-Light'",
    lineHeight: 1.5,
    fontSize: 14,
  },
  underline: {
    '&:before': { borderBottomColor: '#A4ACAF' },
    '&:hover:before': { borderBottom: '2px solid #7C8487 !important' },
    '&:after': { borderBottomColor: '#23BFB8' },
  },
  label: {
    fontFamily: "'ProximaNova-Light'",
    color: '#777B84',
    fontSize: 16,
    '&:hover': { borderBottomColor: '#A4ACAF' },
    '&$labelFocused': { color: '#23BFB8' },
  },
  labelFocused: {},
  tip: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  helperText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  charCount: {
    marginLeft: 20,
  },
  website: {
    alignSelf: 'flex-end',
    marginBottom: 31,
    marginLeft: 3,
    color: '#ADB1BA',
    fontSize: 12,
  }
}

const fancyCount = str => {
  str = str ? str.toString(16) : "";
  const joiner = "\u{200D}";
  const split = str.split(joiner);
  let count = 0;

  for (const s of split) {
    //removing the variation selectors
    const num = Array.from(s.split(/[\ufe00-\ufe0f]/).join("")).length;
    count += num;
  }

  //assuming the joiners are used appropriately
  return count / split.length;
};

const LabeledSubdomainInput = ({ meta: { touched, error } = {}, input: {...inputProps}, classes, ...props}) => {

  const helperText = (
    <div style={style.helperText}>
      <div>{touched && error}</div>
      {props.maxLength && (<div style={style.charCount}>{fancyCount(_.get(inputProps, 'value'))} / {props.maxLength}</div>)}
    </div>
  )

  return (
    <div style={style.wrapper}>
      <TextField
        {...inputProps}
        {...props}
        error={touched && !!error}
        helperText={helperText}
        FormHelperTextProps={{
          component: 'div'
        }}
        fullWidth
        inputProps={{
          maxLength: props.maxLength
        }}
        InputProps={{
          style: {
            textAlign: 'justify',
            MozTextAlignLast: 'right',
            textAlignLast: 'right',
          },
          classes: {
            root: classes.input,
            underline: classes.underline,
          }
        }}
        InputLabelProps={{
          shrink: props.shrink,
          classes: {
            root: classes.label,
            focused: classes.labelFocused,
          }
        }}
      />
    <div style={style.website}>.gogreenback.com</div>
      <div style={style.tip}>
        {props.tip && !props.disabled ? (<Tooltip message={props.tip} position={'left'} className="toolTip">?</Tooltip>) : null }
      </div>
    </div>

  )
}

export default withStyles(style)(LabeledSubdomainInput)
