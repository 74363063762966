import React from 'react';

let style = {
  root: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    height: 60,
    width: '100%',
    background: 'linear-gradient(90deg, #1E5E75 0%, #0E9BA6 63%)',
  },
  logo: {
    height: 30,
    marginLeft: 20,
    display: 'block',
  },
}

const Header = (props) => {
  return (
    <div style={style.root}>
      <img style={style.logo} src="https://cloudfront.startupmedia.com/greenback/logos/gb-logo-icon-light.png" />
    </div>
  )
}


export default Header;
