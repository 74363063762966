import React from 'react';

let style = {
  root: {
    flexShrink: 0,
    paddingTop: 20,
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
    borderTop: '1px solid #ADB1BA',
  },
  menu: {
    textAlign: 'center',
    fontSize: 12,
    color: '#525868',
  },
  link: {
    color: '#525868',
    marginLeft: 10,
    marginRight: 10,
    textDecoration: 'none',
  },
  copyright: {
    textAlign: 'center',
    color: '#525868',
    fontSize: 12,
    marginTop: 20,
  }
}

const Footer = (props) => {

  const copyrightYear = new Date().getFullYear();

  return (
    <div style={style.root}>
      <div style={style.menu}>
        <a href="https://www.startupgreenback.com/privacy-noauth" target="_blank" style={style.link}>Privacy Policy</a>
        |
        <a href="https://www.startupgreenback.com/terms-noauth" target="_blank" style={style.link}>Terms & Conditions</a>
        |
        <a href="mailto:help@gogreenback.com" style={style.link}>Contact Us</a>
      </div>
      <div style={style.copyright}>
        &copy; greenback {copyrightYear}
      </div>
    </div>
  )
}


export default Footer;
