import React from 'react';
import {
  Header,
  Footer,
  Card
} from './../';

let style = {
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    height: '100vh',
  },
  card: {
    paddingTop: 60,
    paddingBottom: 60,
    '@media (max-width: 600px)': {
      paddingTop: 20,
      paddingBottom: 20,
    }
  },
  content: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    maxWidth: 900,
    width: '95%',
    marginTop: 40,
    marginBottom: 40,
  },
  title: {
    textAlign: 'center',
    fontSize: 36,
    '@media (max-width: 600px)': {
      fontSize: 22,
    }
  },
  caption: {
    marginTop: 40,
    lineHeight: 1.7,
    maxWidth: 700,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (max-width: 600px)': {
      fontSize: 14
    }
  },
  link: {
    color: '#0e9ba6',
    marginLeft: 10,
    marginRight: 10,
    textDecoration: 'none',
  },
}

const Unauthorized = (props) => {
  
  return (
    <div style={style.root}>
      <Header />
      <div style={style.content}>
        <Card root={style.card}>
          <div style={style.title}>Uh-oh! It appears that this link is no longer valid.</div>
          <div style={style.caption}>For security purposes, our links expire after seven (7) days. Please contact our customer service team at <a href="mailto:help@gogreenback.com" style={style.link}>help@gogreenback.com</a> to receive a new link!</div>
        </Card>
      </div>
      <Footer />
    </div>
  )
}


export default Unauthorized;
