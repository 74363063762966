import loopbackRestClient from './../aor-loopback';
import config from './../config/config.provider';
const restClient = loopbackRestClient(config)

export const CREATE_REGISTRATION_BEGIN   = 'GGB/CREATE_REGISTRATION_BEGIN';
export const CREATE_REGISTRATION_SUCCESS = 'GGB/CREATE_REGISTRATION_SUCCESS';
export const CREATE_REGISTRATION_FAILURE = 'GGB/CREATE_REGISTRATION_FAILURE';

export const createRegistrationBegin = data => ({
  type: CREATE_REGISTRATION_BEGIN,
  payload: {data}
});

export const createRegistrationSuccess = data => ({
  type: CREATE_REGISTRATION_SUCCESS,
  payload: { data }
});

export const createRegistrationFailure = error => ({
  type: CREATE_REGISTRATION_FAILURE,
  payload: { error }
});

export function createRegistration({registration, account_manager_id}) {
  return new Promise((resolve, reject) => {
    restClient("CREATE","registrations",{
      data: {
        account_manager_id,
        registration
      }
    }).then(resolve).catch(reject);
  });
}
