import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
  Card,
  Title,
  Caption,
  FormCard,
  LabeledTextInput,
  GreenButton,
  BackButton
} from './../';
import {
  required,
  ein,
  phoneNumber,
  zipCode,
  url,
} from './../../validators';

import {
  navigateForward
} from './../../actions/navigate';

let style = {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  col: {
    width: '45%',
  },
  button: {
    marginTop: 10,
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}


let RegisterEntityForm = (props) => {

  function submit(values) {
    props.navigateForward({stepProps: props});
  }

  const { error, handleSubmit, pristine, reset, submitting } = props
  return (
    <Card>
      <Title>{props.title}</Title>
      <Caption>{props.caption}</Caption>
      <FormCard>
        <form onSubmit={handleSubmit(submit)}>
          <Field
            component={LabeledTextInput}
            label="Legal Entity Name"
            placeholder="Your Organization Name"
            name="templating.legal.name"
            validate={[required]}
          />
        {props.type === "Cause" && (
            <Field
              component={LabeledTextInput}
              label="EIN"
              placeholder="XX-XXXXXXX"
              name="templating.legal.ein"
              validate={[required,ein]}
            />
          )}
          <Field
            component={LabeledTextInput}
            label="Street Address"
            placeholder="123 Example Street"
            name="templating.legal.street"
            validate={[required]}
          />
          <Field
            component={LabeledTextInput}
            label="City"
            placeholder="Orlando"
            name="templating.legal.city"
            validate={[required]}
          />
          <div style={style.row}>
            <div style={style.col}>
              <Field
                component={LabeledTextInput}
                label="State"
                placeholder="FL"
                name="templating.legal.state"
                validate={[required]}
              />
            </div>
            <div style={style.col}>
              <Field
                component={LabeledTextInput}
                label="Zip Code"
                placeholder="32801"
                name="templating.legal.zip"
                validate={[required,zipCode]}
                type="number"
              />
            </div>
          </div>
          <Field
            component={LabeledTextInput}
            label="Organization's Phone Number"
            placeholder="XXX-XXX-XXXX"
            name="templating.legal.phone_number"
            validate={[required,phoneNumber]}
            type="tel"
          />
          <Field
            component={LabeledTextInput}
            label="Website"
            placeholder="http://www.website.com"
            name="gb_cause.website"
            validate={[url]}
            type="url"
          />
          <GreenButton type="submit" label="Continue" small="true" style={style.button}/>
        </form>
        <div root= {{textAlign: 'center'}}>
      {'By clicking continue, you agree to the '}{<a href='https://www.startupgreenback.com/terms-noauth' target="_blank">Terms & Conditions</a>}{', and acknowledge receipt of our '}{<a href='https://www.startupgreenback.com/privacy-noauth' target="_blank">Privacy Policy</a>}{'.'}
        </div>
      </FormCard>
    </Card>
  )
}

RegisterEntityForm = reduxForm({
  form: 'entity-registration_entity',
})(RegisterEntityForm);


export default connect(undefined, {navigateForward})(RegisterEntityForm);
