import loopbackRestClient from './../aor-loopback';
import config from './../config/config.provider';
const restClient = loopbackRestClient(config)

export const FETCH_ACCOUNTMANAGER_BEGIN   = 'GGB/FETCH_ACCOUNTMANAGER_BEGIN';
export const FETCH_ACCOUNTMANAGER_SUCCESS = 'GGB/FETCH_ACCOUNTMANAGER_SUCCESS';
export const FETCH_ACCOUNTMANAGER_FAILURE = 'GGB/FETCH_ACCOUNTMANAGER_FAILURE';

export const fetchAccountManagerBegin = data => ({
  type: FETCH_ACCOUNTMANAGER_BEGIN,
  payload: {data}
});

export const fetchAccountManagerSuccess = data => ({
  type: FETCH_ACCOUNTMANAGER_SUCCESS,
  payload: { data }
});

export const fetchAccountManagerFailure = error => ({
  type: FETCH_ACCOUNTMANAGER_FAILURE,
  payload: { error }
});

export function fetchAccountManagerByToken({token, action}) {
  return new Promise((resolve, reject) => {
    if (!token) return reject(new Error("NO_TOKEN"));
    restClient('WITH_ROUTE','accountmanagers',{
      "route": `getByAccessToken/${token}`
    }).then(resolve).catch(reject);
  });
}
