import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Route,
} from "react-router-dom";

import {
  fetchAccountManagerBegin
} from './../../actions/accountManager';

class PrivateRoute extends React.Component {

  render() {

    const {
      component: Component,
      fetchAccountManagerBegin,
      ...rest
    } = this.props;

    return (
      <Route
        {...rest}
        render={props => fetchAccountManagerBegin(_.get(props, 'match.params')) && <Component {...props} />}
      />
    );
  }
}

export default connect(null, {fetchAccountManagerBegin})(PrivateRoute);
