import { combineReducers } from 'redux';
import accountManagerReducer from './accountManager';
import causeSelectionReducer from './causeSelection';
import registrationReducer from './registration';
import navigateReducer from './navigate';
import { reducer as formReducer } from 'redux-form';

const rootReducer = combineReducers({
  accountManagerReducer,
  causeSelectionReducer,
  registrationReducer,
  navigateReducer,
  form: formReducer
});

export default rootReducer;
