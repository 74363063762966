import { put, takeEvery, takeLatest, all,  select, call} from 'redux-saga/effects';
import {
  LOCATION_CHANGE_FORWARD,
} from './../actions/navigate';

export const getState = (state) => {
  return state.navigateReducer;
}

function* onLocationChangeForward(action) {
  const { type } = action;
  const { stepProps, inReviewMode } = yield select(getState);
  if (inReviewMode) {
    yield call(stepProps.lastStep);
  }
  else {
    yield call(stepProps.nextStep);
  }
}

export default function* watchNavigate() {
  yield all([
    takeLatest(LOCATION_CHANGE_FORWARD, onLocationChangeForward),
  ]);
}
