import React from 'react';
import Radium from 'radium';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form';
import { Redirect } from "react-router-dom";
import _ from 'lodash';
import {
  Card,
  Title,
  Caption,
  FormCard,
  LabeledTextField,
  ImageField,
  GreenButton,
  BackButton,
} from './../';
import {
  getLogo
} from './../../services';

import {
  navigateFromReview
} from './../../actions/navigate';
import {
  createRegistrationBegin
} from './../../actions/registration';

let style = {
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  content: {
    marginLeft: 40,
    marginRight: 40,
    marginTop: 40,
    '@media (max-width: 600px)': {
      marginLeft: 0,
      marginRight: 0,
    }
  },
  item: {
    position: 'relative',
    marginBottom: 40,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 16,
    fontFamily: 'ProximaNova-SemiBold',
    letterSpacing: 1.5,
    marginLeft: 20,
    '@media (max-width: 600px)': {
      fontSize: 14,
      marginLeft: 10,
    }
  },
  edit: {
    fontSize: 12,
    letterSpacing: 1,
    color: '#E00A1F',
    fontFamily: 'ProximaNova-SemiBold',
    alignSelf: 'flex-end',
    cursor: 'pointer',
  },
  wrapper: {
    marginTop: 20,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: 'rgba(229,246,245,0.8)',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
      marginTop: 10,
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      flexDirection: 'column',
    }
  },
  causeWrapper: {
    borderColor: '#34B0A7',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    marginTop: 20,
    maxHeight: 240,
    height: 'auto',
    overflow: 'auto',

  },
  col: {
    width: '45%',
    '@media (max-width: 600px)': {
      width: '100%',
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    }

  },
  field: {
    marginBottom: 20,
  },
  rowCause: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    '@media (max-width: 600px)': {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 10,
      paddingRight: 10,
    }
  },
  rowCauseEven: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#F3F5F6',
    '@media (max-width: 600px)': {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 10,
      paddingRight: 10,
    }
  },
  logo: {
    height: 45,
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      height: 30,
      width: 80,
    }
  },
  logoImg: {
    maxHeight: 45,
    height: 'auto',
    maxWidth: 100,
    width: 'auto',
    '@media (max-width: 600px)': {
      maxHeight: 30,
      maxWidth: 80,
    }
  },
  name: {
    fontFamily: 'ProximaNova-Regular',
    flex: 1,
    marginLeft: 20,
    '@media (max-width: 600px)': {
      fontSize: 12,
      marginLeft: 10,
    }
  },
}

let ReviewForm = (props) => {

  const edit = (step) => {
    props.goToStep(step)
    props.navigateFromReview();
  }

  const submit = () => {
    props.createRegistrationBegin({
      ...props.record,
      registration_type: props.registrationType
    });
  }

  if (_.get(props, 'registration_state.loading')) return (<Redirect to='/join-complete' />);

  return (
    <Card>
      <Title>Review Your Information</Title>
      <Caption>Before clicking Finish Registration below please check to make sure that the information you have entered is correct.</Caption>

      <div style={style.content}>

        <div style={style.item}>
          <div style={style.header}>
            <div style={style.title}>Legal Entity Information</div>
            <div style={style.edit} onClick={() => edit(1)}>edit</div>
          </div>
          <div style={style.wrapper}>
            <div style={style.col}>
              <LabeledTextField
                label="Legal Entity Name"
                value={_.get(props, 'record.templating.legal.name', '')}
                style={style.field}
              />
              {props.type === "Cause" && (
                <LabeledTextField
                  label="EIN"
                  value={_.get(props, 'record.templating.legal.ein', '')}
                  style={style.field}
                />
                )}
                <LabeledTextField
                  label="Organization's Phone Number"
                  value={_.get(props, 'record.templating.legal.phone_number', '')}
                  style={style.field}
                />
              <LabeledTextField
                label="Website"
                value={_.get(props, 'record.gb_cause.website', '')}
                style={style.field}
              />
            </div>
            <div style={style.col}>
              <LabeledTextField
                label="Street Address"
                value={_.get(props, 'record.templating.legal.street', '')}
                style={style.field}
              />
              <LabeledTextField
                label="City"
                value={_.get(props, 'record.templating.legal.city', '')}
                style={style.field}
              />
              <div style={style.row}>
                <div style={style.col}>
                  <LabeledTextField
                    label="State"
                    value={_.get(props, 'record.templating.legal.state', '')}
                    style={style.field}
                  />
                </div>
                <div style={style.col}>
                  <LabeledTextField
                    label="Zip"
                    value={_.get(props, 'record.templating.legal.zip', '')}
                    style={style.field}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={style.item}>
          <div style={style.header}>
            <div style={style.title}>{props.type} Manager Details</div>
            <div style={style.edit} onClick={() => edit(2)}>edit</div>
          </div>
          <div style={style.wrapper}>
            <div style={style.col}>
              <LabeledTextField
                label="First Name"
                value={_.get(props, 'record.entity_manager.first_name', '')}
                style={style.field}
              />
              <LabeledTextField
                label="Last Name"
                value={_.get(props, 'record.entity_manager.last_name', '')}
                style={style.field}
              />
            </div>
            <div style={style.col}>
              <LabeledTextField
                label="Email Address"
                value={_.get(props, 'record.entity_manager.email', '')}
                style={style.field}
              />
              <LabeledTextField
                label="Contact Phone Number"
                value={_.get(props, 'record.entity_manager.phone_number', '')}
                style={style.field}
              />
            </div>
          </div>
        </div>

        <div style={style.item}>
          <div style={style.header}>
            <div style={style.title}>{props.type} Details</div>
            <div style={style.edit} onClick={() => edit(3)}>edit</div>
          </div>
          <div style={style.wrapper}>
            <div style={style.col}>
              <ImageField
                label={props.type + ' Logo'}
                value={_.get(props, 'record.templating.logo', '')}
                style={style.field}
              />
              <LabeledTextField
                label={props.type + ' Name'}
                value={_.get(props, 'record.gb_cause.name', '')}
                style={style.field}
              />
            </div>
            <div style={style.col}>
              <LabeledTextField
                label={props.type + ' Mission or Description'}
                value={_.get(props, 'record.gb_cause.description', '')}
                multiline
                rows={5}
                style={style.field}
              />
              <LabeledTextField
                label={props.type + ' Site URL'}
                value={_.get(props, 'record.gb_cause.subdomain', '') + ".gogreenback.com"}
                style={style.field}
              />
            </div>
          </div>
        </div>

        {props.registrationType.includes("cause") && (
          <div style={style.item}>
            <div style={style.header}>
              <div style={style.title}>Donation Collection: PayPal</div>
              <div style={style.edit} onClick={() => edit(4)}>edit</div>
            </div>
            <div style={style.wrapper}>
              <div style={style.col}>
                <LabeledTextField
                  label="PayPal Email Address"
                  value={_.get(props, 'record.gb_cause.paypal', '')}
                  style={style.field}
                />
              </div>
            </div>
          </div>
        )}

        {props.registrationType.includes("sponsor") && (
          <div style={style.item}>
            <div style={style.header}>
              <div style={style.title}>Selected Causes</div>
              <div style={style.edit} onClick={() => edit(4)}>edit</div>
            </div>
            <div style={style.causeWrapper}>
              {_.get(props, 'record.selected_causes', []).map((c,i) => (
                <div style={{...style.rowCause, ...(i % 2 ? style.rowCauseEven : '')}} key={i} >
        					<div style={style.logo}>
        						<img style={style.logoImg} src={getLogo({gb_cause_id: c.id})} />
        					</div>
        					<div style={style.name}>{c.name}</div>
        				</div>
              ))}
            </div>
          </div>
        )}



        <GreenButton onClick={submit} label="Finish Registration" small="true" style={style.button}/>
        <BackButton {...props}/>

      </div>
    </Card>
  )
}

ReviewForm = reduxForm({
  form: 'entity-registration',
})(Radium(ReviewForm));

ReviewForm = connect(state => {

  const selectedCauses = _.get(state, 'causeSelectionReducer.record');

  if(selectedCauses) {
    _.set(state, "form.entity-registration_causedetails.values.gb_cause.sponsors_of", _.map(selectedCauses, c => c.id));
  }

  const formsMerged =_.merge({},
    _.get(state, "form.entity-registration_entity.values", {}),
    _.get(state, "form.entity-registration_entitymanager.values", {}),
    _.get(state, "form.entity-registration_causedetails.values", {}),
    _.get(state, "form.entity-registration_paypal.values", {}),
    selectedCauses && {selected_causes: _.get(state,"causeSelectionReducer.record")}
  );

  //console.log(state, formsMerged);

  return {
    record: formsMerged,
    registration_state: state.registrationReducer
  }

}, {
  navigateFromReview,
  createRegistrationBegin
})(ReviewForm);


export default ReviewForm;
