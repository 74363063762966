import {
  CREATE_REGISTRATION_BEGIN,
  CREATE_REGISTRATION_SUCCESS,
  CREATE_REGISTRATION_FAILURE
} from './../actions/registration';

const initialState = {
  record: null,
  loading: false,
  error: null
};

export default function registrationReducer(state = initialState, action) {
  switch(action.type) {
    case CREATE_REGISTRATION_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null,
        record: action.payload.data
      };
    case CREATE_REGISTRATION_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        record: action.payload.data
      };
    case CREATE_REGISTRATION_FAILURE:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere
      // Since it failed, we don't have items to display anymore, so set it empty.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        record: null
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
