import {
  SET_SELECTED_CAUSES,
} from './../actions/causeSelection';

const initialState = {
  record: null,
};

export default function causeSelectionReducer(state = initialState, action) {
  switch(action.type) {
    case SET_SELECTED_CAUSES:
      return {
        ...state,
        record: action.payload.data
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
