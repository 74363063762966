import React from 'react';
import axios from 'axios';
import config from './config'
import {toastr} from 'react-redux-toastr'

const env = config.env;
const target = env === 'production'
  ? '.'
  : `.${env}.`;

const dimensions = '1200x';

const baseConfig = {
  thumborResult({location, rotation}) {
    const img = location.replace('/image/', '');
    const axis = `:rotate(${rotation})`;
    return `https://thumbor-api${target}startupmedia.com/unsafe/${dimensions}/filters:fill(transparent,1)${axis}/${img}`;
  },
  thumborUpload: `https://thumbor-api${target}startupmedia.com/image`,
  thumborS3({location, rotation}) {
    const img = location.replace('/image/', '');
    const axis = `:rotate(${rotation})`;
    return `//thumbor-cdn${target}startupmedia.com/result/unsafe/${dimensions}/filters:fill(transparent,1)${axis}/${img}`;
  }
}

export function loadImage({file}) {
  return new Promise((resolve, reject) => {
    // if (/ /g.test(file.name)) {
    //   return reject({message: 'Oops! Your file name cannot contain spaces.'});
    // }
    if (/.gif/.test(file.name)) {
      toastr.error('Oops!', 'Supported file types are .jpg, and .png.')
      reject();

      // return reject({message: 'Oops! Supported file types are .jpg, and .png.'});
    }
    const fileReader = new FileReader();
    const removeEvents = () => {
      fileReader.onload = null;
      fileReader.onloadend = null;
    };
    fileReader.onload = (e) => {
      const img = new Image();
      const dataUri = e.target.result;
      img.onload = () => {
        if (img.width < 200) {
          toastr.error('Oops!', 'The dimensions of the image that you have selected are too small. Files may be no smaller than 200x200 pixels.')
          reject();
        }
        resolve({file, dataUri});
      };
      img.src = dataUri;
    };
    fileReader.onloadend = removeEvents;

    if (file.size > 20000000) {
      toastr.error('Oops!', 'The file that you have selected is too large. Files must be smaller than 20MB.')
      reject();

      // return reject({message: 'Oops! The file that you have selected is too large. Files must be smaller than 20MB.'});
    }
    fileReader.readAsDataURL(file);
    // console.log('loadImage', file)
  })
}

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else
    byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {type:mimeString});
}

export function uploadThumbor({file, dataUri}) {
  console.log(file, dataUri)
  return new Promise((resolve, reject) => {
    const data = new FormData();
    let fileName = Math.random().toString(36).substring(10);
    data.append('media', file, fileName);
    const url = baseConfig.thumborUpload;
    axios.post(url, data).then((res, arg2, arg3) => {
      resolve({location: res.headers.location, dataUri});
    }).catch((error) => {
      console.error('err', error);
      toastr.error('', 'Error uploading, please try again.')
      reject();

      // return reject({message: 'Error uploading, please try again.'});
    });
  });
}

export function formatImage({location, dataUri, rotation}) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const src = baseConfig.thumborResult({location, rotation});
    const imgUrl = baseConfig.thumborS3({location, rotation});
    let fail = false;
    img.onload = () => {
      let s3Img = new Image();
      s3Img.onload = () => {
        resolve({src: imgUrl, dataUri});
      };
      s3Img.onerror = () => {
        if (fail) {
          toastr.error('', 'Something went wrong, please try again.')
          reject();

          // return reject({message: 'Something went wrong, please try again.'});
        }
        setTimeout(() => {
          s3Img.src += `?${new Date()}`
          console.log('retrying...', img.src);
          fail = true;
        }, 1000);
      }
      s3Img.src = imgUrl;
    };
    img.onerror = (err) => {
      if (fail) {
        // return reject({message: 'Something went wrong, please try again.'});
        reject();

        toastr.error('', 'Something went wrong, please try again.')
      }
      setTimeout(() => {
        img.src += `?${new Date()}`
        console.log('retrying...', img.src);
        fail = true;
      }, 1000);
    };
    setTimeout(() => {
      img.src = src;
    }, 1000);
  });
}
