import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Field, reduxForm } from 'redux-form';
import {
  Card,
  Title,
  Caption,
  FormCard,
  LabeledTextInput,
  GreenButton,
  BackButton
} from './../';
import {
  required,
  phoneNumber,
  email,
  matchEmail,
  url,
  password,
} from './../../validators';

import {
  navigateForward
} from './../../actions/navigate';

import baseRequest from './../../services/api/baseRequest';

let style = {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  col: {
    width: '45%',
  },
  button: {
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

let CreateEntityManagerForm = (props) => {

  function submit(values) {
    props.navigateForward({stepProps: props});
  }

  const { error, handleSubmit, pristine, reset, submitting } = props
  return (
    <Card>
      <Title>{props.title}</Title>
      <Caption>{props.caption}</Caption>
      <FormCard>
        <form onSubmit={handleSubmit(submit)}>
          <Field
            component={LabeledTextInput}
            label="First Name"
            placeholder="John"
            name="entity_manager.first_name"
            validate={required}
          />
          <Field
            component={LabeledTextInput}
            label="Last Name"
            placeholder="Doe"
            name="entity_manager.last_name"
            validate={required}
          />
          <Field
            component={LabeledTextInput}
            label="Contact Phone Number"
            placeholder="XXX-XXX-XXXX"
            name="entity_manager.phone_number"
            validate={[required,phoneNumber]}
            type="tel"
            />
          <Field
            component={LabeledTextInput}
            label="Email"
            placeholder="youremail@domain.com"
            name="entity_manager.email"
            type="email"
            validate={[required, email]}
          />
          <Field
            component={LabeledTextInput}
            label="Confirm Email"
            placeholder="youremail@domain.com"
            name="entity_manager.confirm_email"
            type="email"
            validate={[required, email, matchEmail]}
          />
          <Field
            component={LabeledTextInput}
            label="Password"
            placeholder="******"
            type="password"
            name="entity_manager.password"
            validate={[required, password]}
          />
          <GreenButton type="submit" label="Continue" small="true" style={style.button}/>
          <BackButton {...props}/>
        </form>
      </FormCard>
    </Card>
  )
}

CreateEntityManagerForm = reduxForm({
  form: 'entity-registration_entitymanager',
  asyncValidate: (values) => {
    const email = _.get(values, "entity_manager.email");
    return baseRequest({
      endpoint: `EntityManagers`,
      route: `emailExists/${encodeURIComponent(email)}`,
      method: "GET"
    }).then(res => {
      if (_.get(res, 'exists')) {  
        throw { "entity_manager": {"email": "Sorry! That email address has already been taken."}}
      }
    }).catch(err => {
      throw { "entity_manager": {"email": "Sorry! That email address has already been taken."}}
    });
  },
  asyncBlurFields: ["entity_manager.email"]
})(CreateEntityManagerForm);

export default connect(undefined, {navigateForward})(CreateEntityManagerForm);
