import { put, takeEvery, takeLatest, all,  select, call} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import _ from 'lodash';
import {
  createRegistrationBegin,
  createRegistrationSuccess,
  createRegistrationFailure,
  createRegistration,
} from './../actions/registration';

import {
  CREATE_REGISTRATION_BEGIN,
  CREATE_REGISTRATION_SUCCESS,
  CREATE_REGISTRATION_FAILURE
} from './../actions/registration';

import storage from './../aor-loopback/storage';
import config from './../config/config.provider';

export const getState = (state) => {
  return state.registrationReducer;
}

function* setRegistrationState(action) {
  const { type } = action;
  const { error, loading, record } = yield select(getState);
  try {
    const token = storage.load('lbtoken');
    const account_manager_id = storage.load('account_manager_id');
    if (!token) throw new Error("cannot get user.");
    const registration = yield createRegistration({
      registration: record, 
      account_manager_id
    });
    const response = _.get(registration, 'response.json');
    if (!response) throw new Error(registration);
    yield put(createRegistrationSuccess({
      registration: response
    }));
  }
  catch(err) {
    yield put(createRegistrationFailure(err));
  }
}

function* onRegistrationSuccess(action) {
  const { record } = yield select(getState);
  const token = _.get(record, 'registration.entity_creation_result.entityMgrToken.id');
  if (token) {
    window.location.replace(`${config.entity_management_portal}authbroker/${token}`);
  }
}

export default function* watchRegistration() {
  yield all([
    takeLatest(CREATE_REGISTRATION_BEGIN, setRegistrationState),
    takeLatest(CREATE_REGISTRATION_SUCCESS, onRegistrationSuccess)
  ]);
}
