export const LOCATION_CHANGE_BACKWARD = 'GGB/LOCATION_CHANGE_BACKWARD';
export const LOCATION_CHANGE_FORWARD = 'GGB/LOCATION_CHANGE_FORWARD';
export const TO_STEP_REVISION = 'GGB/TO_STEP_REVISION';
export const SUBMISSION_SUCCESS = '@@redux-form/SET_SUBMIT_SUCCEEDED';

export const navigateBackward = data => ({
  type: LOCATION_CHANGE_BACKWARD,
  payload: {data}
});

export const navigateForward = data => ({
  type: LOCATION_CHANGE_FORWARD,
  payload: {data}
});

export const navigateFromReview = data => ({
  type: TO_STEP_REVISION,
  payload: {data}
});
