import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import {
  Card,
  Title,
  Caption,
  FormCard,
  LabeledTextInput,
  GreenButton,
  BackButton,
  ImageInput,
  LabeledSubdomainInput
} from './../';
import {
  required,
  ein,
  phoneNumber,
  zipCode,
  url,
  alphaNumericNoSpaces
} from './../../validators';
import {
	getCauses
} from './../../services';

import {
  navigateForward
} from './../../actions/navigate';

let style = {
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

let CauseDetailsForm = (props) => {

  function submit(values) {
    props.navigateForward({stepProps: props});
  }

  const { error, handleSubmit, pristine, reset, submitting, type } = props
  return (
    <Card>
      <Title>{type} Details</Title>
      <Caption>Complete the form below by adding your {type} details. The logo, name, and description will be used throughout your greenback sites, and mobile application.</Caption>
      <FormCard>
        <form onSubmit={handleSubmit(submit)}>
          <Field
            {...props}
            component={ImageInput}
            name="templating.logo"
            label={props.type + ' Logo'}
            variant="square"
            defaultValue="//cloudfront.startupmedia.com/greenback/mgmtportal/circle-upload-background.jpg"
            style={{marginBottom:20}}
            validate={required}
          />
          <Field
            component={LabeledTextInput}
            label={props.type + ' Name'}
            placeholder={`A Great ${props.type}`}
            tip="This is the name of your organization, and will be templated across your greenback sites."
            name="gb_cause.name"
            validate={required}
          />
          <Field
            component={LabeledTextInput}
            label={props.type + ' Mission or Description'}
            placeholder={'At A Great ' + props.type + ' we strive to…'}
            multiline
            name="gb_cause.description"
            maxLength={500}
            validate={required}
          />
          <Field
            component={LabeledSubdomainInput}
            label={props.type + ' Site URL'}
            placeholder={`agreat${props.registrationType}`}
            tip={'This name will be used to create your Registered ' + props.type + ' Site URL.'}
            name="gb_cause.subdomain"
            validate={[required,alphaNumericNoSpaces]}
          />
          <GreenButton type="submit" label="Continue" small="true" style={style.button}/>
          <BackButton {...props}/>
        </form>
      </FormCard>
    </Card>
  )
}

CauseDetailsForm = reduxForm({
  form: 'entity-registration_causedetails',
  asyncValidate: (values) => {
    const subdomain = _.get(values, "gb_cause.subdomain");
    return getCauses({
      "filter":{"where": {"subdomain": {"like": subdomain.toLowerCase()}}}
    }).then(res => {
      if(_.find(res, {subdomain: subdomain})) {
        throw { "gb_cause": {"subdomain": "Sorry! That URL has already been taken."}}
      }
    }).catch(err => {
      console.error(err);
      throw { "gb_cause": {"subdomain": "Sorry! That URL has already been taken."}}
    });
  },
  asyncBlurFields: ['gb_cause.subdomain']
})(CauseDetailsForm);

export default connect(undefined, {navigateForward})(CauseDetailsForm);
