import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';


const style = {
  input: {
    color: '#464D5E',
    fontFamily: "'ProximaNova-Light'",
    lineHeight: 1.5,
    fontSize: 14,
    '&:hover': { border: 'none !important' },
    '&:before': {borderBottom: '1px solid #ADB1BA !important'},
  },
  underline: {
    border: 'none',
    '&:before': { borderBottom: '1px solid #ADB1BA' },
    '&:hover:before': { borderBottom: '1px solid #ADB1BA' },
    '&:after': { borderBottom: '1px solid #ADB1BA' },
  },
  label: {
    fontFamily: "'ProximaNova-Light'",
    color: '#777B84',
    fontSize: 16,
    '&:hover': { border: 'none' },
    '&$labelFocused': { color: '#777B84' },
  },
  labelFocused: {},
}

const LabeledTextField = ({
  meta: { touched, error } = {},
  input: {...inputProps},
  classes,
  ...props}) => {
  return (

    <TextField
      {...inputProps}
      {...props}
      error={touched && error}
      helperText={touched && error}
      fullWidth
      inputProps={{
        maxLength: props.maxLength,
        readOnly: true
      }}
      InputProps={{
        classes: {
          root: classes.input,
          underline: classes.underline,
        }
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.label,
          focused: classes.labelFocused,
        }
      }}
    />

  )
}

export default withStyles(style)(LabeledTextField)
