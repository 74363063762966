import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { formEnhancer, getCauses } from './../../services';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Card,
  Title,
  Caption,
  FormCard,
  LabeledTextInput,
  GreenButton,
  BackButton,
  CheckboxInput
} from './../';
import _ from 'lodash';
import {
  email,
} from './../../validators';
import {
  getLogo
} from './../../services';

import {
  setSelectedCauses
} from './../../actions/causeSelection';
import {
  navigateForward
} from './../../actions/navigate';


let style = {
  button: {
    marginTop: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  table: {
    position: 'relative',
    width: '100%',
    height: 400,
    overflow: 'auto',
    borderColor: '#34B0A7',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    '@media (max-width: 600px)': {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 10,
      paddingRight: 10,
    }
  },
  rowEven: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#F3F5F6',
    '@media (max-width: 600px)': {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 10,
      paddingRight: 10,
    }
  },
  logo: {
    height: 45,
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      height: 30,
      width: 80,
    }
  },
  logoImg: {
    maxHeight: 45,
    height: 'auto',
    maxWidth: 100,
    width: 'auto',
    '@media (max-width: 600px)': {
      maxHeight: 30,
      maxWidth: 80,
    }
  },
  name: {
    fontFamily: 'ProximaNova-Regular',
    flex: 1,
    marginLeft: 20,
    '@media (max-width: 600px)': {
      fontSize: 12,
      marginLeft: 10,
    }
  },
  selectAll: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    marginLeft: 20,
    marginRight: 20,
  },
  loader: {
    width: '100%',
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    fontFamily: 'ProximaNova-Regular',
    color: 'red',
    fontSize: 14,
    marginBottom: 20,
  },
}

let renderCauses = ({ causes, meta: {error}}) => (
	<div>
		{error && <div style={style.error}>{error}</div>}
		<div style={style.table} id="table">
			{causes.length < 1 ?
				<div style={style.loader}><CircularProgress size={100} thickness={5}/></div>
			:
				causes.map((c,i) => (
				<div style={{...style.row, ...(i % 2 ? style.rowEven : '')}} key={i} className="row">
					<div style={style.logo}>
						<img style={style.logoImg} src={getLogo({gb_cause_id: c.id})}/>
					</div>
					<div style={style.name}>{c.name}</div>
					<Field
						component={CheckboxInput}
						name={`cause_${c.id}`}
						label={c.name}
					/>
				</div>
			))}
		</div>
	</div>
);

renderCauses = Radium(renderCauses)


class CauseSelectionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      causes: []
    }
  }

  componentDidMount() {
    Promise.all([
      getCauses({
        "filter":{
          "where": {"is_active": true, "is_sponsor": false, "is_joinable_from_generic": true},
          "order": "name ASC"
        }
      })
    ]).then(res => {
      this.setState({
        causes: _.map(res[0], cause => _.pick(cause, ['name','id'])),
      });
    }).catch(err => {
      this.setState({
        causes: []
      });
    });
  }

  submit = (values) => {
    let selectedValues = _.keys(_.omitBy(values, (v) => !v))
    selectedValues = _.map(selectedValues, (v) => parseInt(v.replace('cause_','')));
    const selectedCauses = _.filter(this.state.causes, (c) => selectedValues.includes(c.id));
    this.props.setSelectedCauses(selectedCauses);
    this.props.navigateForward({stepProps: this.props});
  }

  #toggleState = true;
  toggleSelect = (e) => {
    const { change } = this.props
    this.state.causes.forEach((c) => change(`cause_${c.id}`, this.#toggleState))
    this.#toggleState = !this.#toggleState;
  }

  search = (e) => {
    let input = e.target
    let filterBy = input.value.toUpperCase(); // non case sensitive
    let table = document.getElementById('table');
    let row = document.getElementsByClassName('row');

    for (let i = 0; i < row.length; i++) {
      let item = row[i];
      if (item) {
        let txtValue = item.textContent || item.innerText;
        if (txtValue.toUpperCase().indexOf(filterBy) > -1) {
          row[i].style.display = 'flex';
        } else {
          row[i].style.display = "none";
        }
      }
    }
  }

  render() {
    const { error, touched, handleSubmit, pristine, reset, submitting } = this.props
    return (
      <Card>
        <Title>Select Causes to Support</Title>
        <Caption>{this.props.caption}</Caption>

        <form onSubmit={handleSubmit(this.submit)}>
          <div style={style.header}>
            <LabeledTextInput
              label="Search for a Cause"
              placeholder="A Great Cause"
              name="cause"
              type="search"
              onInput={this.search}
              id="searchInput"
            />
            {this.props.registrationType == "sponsorplus" && (
              <div style={style.selectAll}>
                Select All
                <Checkbox onClick={this.toggleSelect}/>
              </div>
            )}
          </div>
          <FieldArray name={`causes`} causes={this.state.causes} component={renderCauses}/>
          <GreenButton type="submit" label="Continue" small="true" style={style.button}/>
          <BackButton {...this.props}/>
        </form>

      </Card>
    )
  }
}

let CauseSelection = reduxForm({
  form: 'entity-registration_choosecause',
  validate: (values, props) => {
    const errors = {};
    const selectedValues = _.omitBy(values, (v) => !v)
    if (props.registrationType !== "sponsorplus" && _.values(selectedValues).length > 3) {
      errors.causes = { _error: "You may only select up to three causes to sponsor."};
    }
    return errors;
  }
})(CauseSelectionForm);

CauseSelection = connect(undefined, {
  setSelectedCauses,
  navigateForward
})(CauseSelection);

export default CauseSelection;
