import { fork } from 'redux-saga/effects';
import watchAccountManager from './accountManager';
import watchNavigate from './navigate';
import watchRegistration from './registration';

export default function* root() {
  yield fork (watchAccountManager);
  yield fork (watchNavigate);
  yield fork (watchRegistration);
}
