import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
  Card,
  Title,
  Caption,
  FormCard,
  LabeledTextInput,
  GreenButton,
  BackButton,
} from './../';
import {
  email,
  required
} from './../../validators';

import {
  navigateForward
} from './../../actions/navigate';

let style = {
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

let PayPalForm = (props) => {

  function submit(values) {
    props.navigateForward({stepProps: props});
  }

  const { error, handleSubmit, pristine, reset, submitting } = props
  return (
    <Card>
      <Title>Connect PayPal Account</Title>
      <Caption>As a Registered Cause, your organization will have access to a comprehensive donor engagement and fundraising platform. We have setup greenback to integrate with PayPal, allowing donations made through your Registered Cause Site(s) to go directly to your PayPal Account.</Caption>
      <Caption>Enter your organization’s existing PayPal Account’s email address, or create a new 501(c)3 PayPal Account <a href="https://www.paypal.com/us/webapps/mpp/donations" target="_blank" style={{color: '#464D5E'}}>here</a>.</Caption>
      <FormCard>
        <form onSubmit={handleSubmit(submit)}>
          <Field
            component={LabeledTextInput}
            label="PayPal Email Address"
            placeholder="yourpaypal@domain.com"
            name="gb_cause.paypal"
            validate={[required,email]}
          />
          <GreenButton type="submit" label="Continue" small="true" style={style.button}/>
          <BackButton {...props}/>
        </form>
      </FormCard>
    </Card>
  )
}

PayPalForm = reduxForm({
  form: 'entity-registration_paypal',
})(PayPalForm);

export default connect(undefined, {navigateForward})(PayPalForm);
