import React from 'react';
import moment from 'moment';
import _ from 'lodash';


export const required = value => (value || typeof value === 'number' ? undefined : 'Required');
export const isValidMoment = value => moment.isMoment( value ) && value.isValid() ? undefined : 'Invalid Date';
export const phoneNumber = value => value && !/^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4}|[a-zA-Z0-9]{7})$/.test(value)  ? 'Please enter a valid phone number. 1 in the beginning is optional, area code is required, spaces or dashes can be used as optional divider between number groups.' : undefined;
export const ein = value => value && !/^\d{2}\-?\d{7}$/.test(value) ? 'Please enter a valid EIN' : undefined;
export const zipCode = value => value && !/^\d{5}$/.test(value) ? 'Please enter a valid US zip code' : undefined;
export const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value) ? 'Invalid email address' : undefined;
export const matchEmail = (value, allValues) => {
  return value !== _.get(allValues,'entity_manager.email') ? 'Email addresses do not match.  Please be sure your email address is correct.' : undefined;
}

export const url = value => value && !/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value) ? "Invaild URL. Please format as 'http://www'" : undefined;

export const password = value => value && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/.test(value) ? 'Password must be at between 8 - 16 characters, must include at least one upper case letter, and one numeric digit.' : undefined;

export const alphaNumericNoSpaces = value => value && !/^[a-z0-9]+$/.test(value) ? 'Please enter only lowercase alphanumeric characters with no spaces' : undefined;
