import React from 'react';
import StepWizard from 'react-step-wizard';
import _ from 'lodash';
import {
  Header,
  Footer,
  RegisterEntity,
  CreateEntityManager,
  CauseDetails,
  PayPal,
  Review,
  Card,
  CauseSelection,
  Nav,
} from './../';

let style = {
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  content: {
    position: 'relative',
    flex: 1,
    maxWidth: 900,
    width: '95%',
    marginTop: 40,
    marginBottom: 40,
  },
}

const RegisterCause = (props) => {

  const steps = [
    'Register Cause',
    'Add Manager',
    'Cause Details',
    'Donation Collection',
    'Review'
  ];

  return (
    <div style={style.root}>
      <Header />
      <div style={style.content}>
        <StepWizard isHashEnabled={true} nav={<Nav steps={steps}/>} isLazyMount={false}>
          <RegisterEntity
            hashKey={'cause-join/register'}
            type="Cause"
            title="Register Cause"
            caption="Setup your organization as a greenback Registered Cause by providing your legal entity information."
          />
          <CreateEntityManager
            hashKey={'cause-join/create-manager'}
            title="Add Cause Manager"
            caption="Create your Registered Cause Manager Account by completing the form below."
          />
          <CauseDetails
            hashKey={'cause-join/details'}
            registrationType="cause"
            type="Cause"
          />
          <PayPal
            hashKey={'cause-join/paypal'}
          />
          <Review
            hashKey={'cause-join/review'}
            registrationType="cause"
            type="Cause"
          />
        </StepWizard>
      </div>
      <Footer />
    </div>
  )
}


export default RegisterCause;
