import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Tooltip } from './../';

const style = {
  wrapper: {
    position: 'relative',
    width: '100%',
  },
  input: {
    color: '#464D5E',
    fontFamily: "'ProximaNova-Light'",
    lineHeight: 1.5,
    fontSize: 14,
  },
  underline: {
    '&:before': { borderBottomColor: '#A4ACAF' },
    '&:hover:before': { borderBottom: '2px solid #7C8487 !important' },
    '&:after': { borderBottomColor: '#23BFB8' },
  },
  label: {
    fontFamily: "'ProximaNova-Light'",
    color: '#777B84',
    fontSize: 16,
    '&:hover': { borderBottomColor: '#A4ACAF' },
    '&$labelFocused': { color: '#23BFB8' },
  },
  labelFocused: {},
  tip: {
    position: 'absolute',
    top: 0,
    right: 0,
  }
}



const LabeledNumberInput = ({
  meta: { touched, error } = {},
  input: {...inputProps},
  classes,
  ...props}) => {

  return (
    <div style={style.wrapper}>
      <TextField
        {...inputProps}
        {...props}
        error={touched && error}
        helperText={touched && error}
        fullWidth
        inputProps={{
          maxLength: props.maxLength
        }}
        InputProps={{
          classes: {
            root: classes.input,
            underline: classes.underline,
          }
        }}
        InputLabelProps={{
          classes: {
            root: classes.label,
            focused: classes.labelFocused,
          }
        }}
      />
      <div style={style.tip}>
        {props.tip && !props.disabled ? (<Tooltip message={props.tip} position={'left'} className="toolTip">?</Tooltip>) : null }
      </div>
    </div>

  )
}

export default withStyles(style)(LabeledNumberInput)
